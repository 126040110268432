import en from '~/common/en.json';
import fr from '~/common/fr.json';

// This is the list of languages that application supports
export const supportedLngs = ['en', 'fr'];

// This is the language we want to use in case
// if the user language is not in the supportedLngs
export const fallbackLng = 'fr';

// The default namespace
export const defaultNS = 'common';

export const resources = {
  en: { common: en },
  fr: { common: fr },
};

export default {
  supportedLngs,
  fallbackLng,
  defaultNS,
  resources,
};
