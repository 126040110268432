import { ApolloProvider } from '@apollo/client';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getInitialNamespaces } from 'remix-i18next/client';
import i18next from 'i18next';
import { NODE_ENV, SENTRY_DSN } from './constants';
import i18n from './i18n';
import { getApolloClient } from './apolloClient';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
  enabled: NODE_ENV !== 'development',
  environment: NODE_ENV,
  tracesSampleRate: NODE_ENV === 'production' ? 0.1 : 1.0,
  tracePropagationTargets: [/^https:\/\/(api|nest-api).*\.gohomy\.ca/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

async function hydrate() {
  // eslint-disable-next-line import/no-named-as-default-member
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        requestOptions: {
          cache:
            process.env.NODE_ENV === 'development' ? 'no-store' : 'default',
        },
      },
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <ApolloProvider client={getApolloClient()}>
            <RemixBrowser />
          </ApolloProvider>
        </StrictMode>
      </I18nextProvider>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  window.setTimeout(hydrate, 1);
}
